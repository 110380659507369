import ShopifyButton from '@components/Miscellaneous/ShopifyButton';
import FastSpringButton from '@components/Miscellaneous/FastSpringButton';
import { useLocale } from '../providers/Locale';

export function useExternalPurchaseMethod() {
  const { locale } = useLocale();
  const shopifyRegions = process.env.SHOPIFY_REGIONS.split(',');

  return shopifyRegions.includes(locale?.localeDetails?.isoCode)
    ? ShopifyButton
    : FastSpringButton;
}
