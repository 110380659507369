import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import LicenseModule from '@modules/Licenses';
import { navigate, Link } from 'gatsby';

const IndexPage = function() {
  const { t } = useTranslation('page');

  return (
    <>
      <SEO title={t('oneTimePurchase')} />
      <div className="row">
        <div className="col">
          <PageHeader>{t('oneTimePurchase')}</PageHeader>
        </div>
      </div>

      <LicenseModule />
      <div className="text-center mt-4">
        <div className="learn-more-link">
          <button
                onClick={() => navigate('/compare')}
                className="btn btn-outline-secondary ml-2"
              >
                {t('compare:general.title')}
              </button>
        </div>
        {t('common:subscriptionLicense.toggle.preferencedText')}&nbsp;
        <Link id={'onetime'}
            data-testid={'onetime'}
            to={`/`}>
              {t('common:subscriptionLicense.toggle.subscription')}?
          </Link>
      </div>  
    </>
  );
};

export default IndexPage;
