import styled, { css } from 'styled-components';
import Card from '@ui/Card';
import { DropdownToggle } from 'reactstrap';

const highlightStyle = `
box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.16);
border: 1px solid #005b94;
`;
export const PlanCard = styled(Card)`
  // height: 100%;
  width: 100%;
  //padding-left: 20px !important;
  text-align: center;
  // color: #231f20;
  // background-color: #efeff0;
  transition-property: box-shadow, border;
  transition-duration: 0.15s;
  border: 1px solid transparent;
  &.selected {
    ${highlightStyle}
  }
  ${props =>
    !props.noHover &&
    css`
      :hover {
        ${highlightStyle}
      }
    `}
`;
PlanCard.Header = styled(Card.Header).attrs({
  className: 'pt-4 pr-4 pb-0 pl-4',
})`
  // padding: 2.5rem 1.25rem 0 1.25rem;
  border-bottom: none;
  background: none;
  padding-bottom: 0em;
  text-transform: uppercase;
  font-size: 1.125rem;
`;
PlanCard.Body = styled(Card.Body)`
  padding-top: 0.5rem;
  padding-left: 20px;
  //padding-right: 0px;
  //   p {
  //       font-size:0.875rem;
  //   }
  ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      font-size: 0.875rem;
    }
  }
  .description {
    font-size: 0.75rem;
  }
  .trial-link {
    margin-top: 1rem;
    // margin-bottom: 1.25rem;
  }
`;
PlanCard.Footer = styled(Card.Footer).attrs({
  className: 'pt-0 pr-4 pb-4 pl-4',
})`
  background: none;
  // padding: 0 1.25rem 2.5rem 1.25rem;
  .plan-card-disclaimer {
    font-size: 0.75rem;
  }
`;

export const TermDropdownToggle = styled(DropdownToggle)`
  background: transparent;
  border: 1px solid #efeff0;
  color: black;
  :hover {
    background: transparent;
    border: 1px solid #efeff0;
    color: black;
  }
`;

export const LinkButton = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  color: #005bac;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
