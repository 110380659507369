import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { useExternalPurchaseMethod } from '@util/hooks/useExternalPurchaseMethod';

export default function PlanCardFooter({ plan }) {
  const { t } = useTranslation();
  const buttonText = t(plan?.action?.text);
  const ExternalPurchaseButton = useExternalPurchaseMethod();

  return (
    <>
      <div>
        {plan.action.route ? (
          <Link to={plan?.action?.route} className="btn btn-primary">
            {buttonText}
          </Link>
        ) : (
          <ExternalPurchaseButton />
        )}
      </div>
      <div className="plan-card-disclaimer mt-2">
        {t(plan?.staticRatePlan?.disclaimerKey)}
      </div>
    </>
  );
}
