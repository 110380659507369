import React from 'react';
import { useTranslation } from 'react-i18next';
import '@i18n/i18n';
import {
  RateInfo,
  Price,
  StaticPrice,
  RateInfoSmall,
  PriceSmall,
  StaticPriceSmall,
  SavingsWithCurrency,
} from '@ui/RateInfo';

import { formatCurrency, getSubscriptionCurrency } from '@util/functions';
import { useLocale } from '@util/providers/Locale';

export default function({ rate, plan, myPlan = false, showDisplayPrice }) {
  if (rate && rate.price) {
    if (myPlan) {
      return (
        <RateInfoSmall>
          {plan && plan.staticRatePlan && !plan.hasStaticRatePlanPrice ? (
            <StaticPriceSmall>{rate.price}</StaticPriceSmall>
          ) : (
            <PriceSmall>
              <PriceHandler showDisplayPrice={showDisplayPrice}>
                {rate}
              </PriceHandler>
            </PriceSmall>
          )}

          <TermHandler rate={rate} plan={plan} />
        </RateInfoSmall>
      );
    }
    return (
      <RateInfo>
        {plan && plan.staticRatePlan && !plan.hasStaticRatePlanPrice ? (
          <StaticPrice>{rate.price}</StaticPrice>
        ) : (
          <Price>
            <PriceHandler showDisplayPrice={showDisplayPrice}>
              {rate}
            </PriceHandler>
          </Price>
        )}

        <TermHandler rate={rate} plan={plan} />
      </RateInfo>
    );
  }

  return null;
}

function PriceHandler({ children: rate, showDisplayPrice }) {
  const { selectedCurrency } = useLocale();
  const subscriptionCurrency = getSubscriptionCurrency(selectedCurrency);
  return (
    rate.price && (
      <>
        {showDisplayPrice
          ? rate.price
          : formatCurrency(rate.price, subscriptionCurrency, {
              withLabel: false,
              trim: rate.price === parseInt(rate.price), // format currency to remove unnecessary fractional units ex: $99.00 as $99
            })}
        {!showDisplayPrice && <small>{` ${subscriptionCurrency}`}</small>}
      </>
    )
  );
}

function TermHandler(props) {
  const { t } = useTranslation();
  const { rate } = props;
  return (
    <h6>
      {rate.isYearly && !isNaN(rate.price) && (
        <SavingsWithCurrency
          {...props}
          withLabel={false}
          className="text-gray"
        />
      )}{' '}
      <span>
        {rate.isMonthly && t('PerMonth')}
        {rate.isYearly && t('PerYear')}
        {!rate.isMonthly && !rate.isYearly && rate.termName}
      </span>
    </h6>
  );
}
