import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import LicensePlanCard from '@components/PlanCard/License/LicensePlanCard';
import { CardContainer, PlanList } from '@ui/Plans';
import { staticPlanDetailsLicenses } from '@data';
import { useLicense } from '@util/providers/License';
import { useLocale } from '@util/providers/Locale';
import useSubscription from '@util/hooks/useSubscription';

const LicenseModule = function() {
  const plans = staticPlanDetailsLicenses();
  const { licensePlans } = useLicense();
  const { locale } = useLocale();

  const pricingKey = locale?.localeDetails?.isoCode;

  const { isSubscriber, isActiveSubscriber } = useSubscription();
  if (isSubscriber && isActiveSubscriber) {
    navigate('/');
  }

  if (!licensePlans.pricing[pricingKey]) {
    return null;
  }
  plans.find(plan => plan.key === 'boardmakerLicense').staticRatePlan.price =
    licensePlans.pricing[pricingKey].display ||
    licensePlans.pricing[pricingKey].price;
  return (
    <>
      <div className="pt-5">
        <PlanCardWidthRestrictor className="justify-content-center">
          {plans &&
            plans.map((p, i) => (
              <CardContainer key={i} className="col-xl-4">
                <LicensePlanCard
                  plan={p}
                  className="mb-xl-0"
                  showDisplayPrice={licensePlans.pricing[pricingKey].display}
                />
              </CardContainer>
            ))}
        </PlanCardWidthRestrictor>
      </div>
    </>
  );
};

export default LicenseModule;
const PlanCardWidthRestrictor = styled(PlanList)`
  @media (min-width: 992px) {
    padding: 0 8.5rem 0;
  }
`;
