import React from 'react';
import { useLocale } from '@util/providers/Locale';
import { useTranslation } from 'react-i18next';

export default function ShopifyButton() {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const isoCode = mapRegionsToShopify(
    locale.localeDetails?.isoCode
  ).toLowerCase();
  const shopifyLink = `https://${isoCode}.tobiidynavox.com/products/boardmaker-7-standard`;
  return (
    <>
      <a className="btn btn-primary" href={shopifyLink}>
        {t('action.buyNow')}
      </a>
    </>
  );
}

function mapRegionsToShopify(isoCode) {
  return isoCode === 'GB' ? 'UK' : isoCode;
}
