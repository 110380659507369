import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import RateInfo from '@components/PlanCard/RateInfo';
import { PlanCard } from '@ui/PlanCard';
import LicensePlanCardFooter from './LicensePlanCardFooter';

import ArrowLink from '@components/Miscellaneous/ArrowLink';

export default function({
  plan,
  className,
  hideLearnMoreLink,
  showDisplayPrice,
}) {
  const { t } = useTranslation();
  return (
    <>
      <PlanCard className={className}>
        <PlanCard.Header className="text-dark">{plan.name}</PlanCard.Header>
        <PlanCard.Body className="pb-2">
          <p className="description d-none d-lg-block">
            {plan.shortDescription}
          </p>
          <RateInfo
            rate={plan.staticRatePlan}
            plan={plan}
            showDisplayPrice={showDisplayPrice}
          />
          {!hideLearnMoreLink && (
            <div className="learn-more-link">
              <ArrowLink
                id={'LearnMore' + plan.key}
                data-testid={'LearnMore' + plan.key}
                to={`/${plan.key}`}
              >
                {t('action.learnMore')}
              </ArrowLink>
            </div>
          )}
          <div className="trial-link">
            <p>
              {/* prettier-ignore */}
              {/* 
                Allows License Plan Card note to be driven by the data
                Each span allows a different style of the word free that
                can be referenced in a node in i18n files dynamically.

                This allows us to avoid using a hard coded conditional to manipulate the free text format.
              */}
              <Trans i18nKey={'plans:' + plan.key + '.note'}>
                <span className="text-capitalize font-weight-bold">free</span>
                <span className="font-weight-bold">free</span>
              </Trans>
            </p>
          </div>
        </PlanCard.Body>
        <PlanCard.Footer className="border-0">
          <LicensePlanCardFooter plan={plan} />
        </PlanCard.Footer>
      </PlanCard>
    </>
  );
}
