import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '@util/providers/Locale';
import Helmet from 'react-helmet';
import i18n from '../../i18n/i18n';
import { navigate } from 'gatsby';

function handleFSStoreClose(orderReference) {
  if (orderReference) {
    window.fastspring.builder.reset();
    navigate('/license/thankyou', {
      state: {
        purchase: {
          method: 'fastspring',
          ref: orderReference,
        },
      },
    });
  }
}

export default function FastSpringButton() {
  const { t } = useTranslation();
  const { locale } = useLocale();
  //* sets globally scoped function to our component scoped function
  window.onFSPopupClosed = handleFSStoreClose;
  return (
    <>
      <Helmet>
        <script
          id="fsc-api"
          src="https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.0/fastspring-builder.min.js"
          type="text/javascript"
          data-storefront={process.env.FASTSPRING_STOREFRONT_URL}
          data-popup-closed="onFSPopupClosed"
        ></script>
        <script src="/js/fastspring.js"></script>
      </Helmet>
      <button
        className="btn btn-primary"
        data-fsc-action="Add,Checkout"
        data-fsc-item-path-value={process.env.FASTSPRING_PRODUCT_KEY}
        onClick={() => {
          const fastspring = window.fastspring;
          fastspring.builder.add(process.env.FASTSPRING_PRODUCT_KEY);
          fastspring.builder.country(locale?.localeDetails?.isoCode);
          fastspring.builder.language(i18n.language.substring(0, 2));
          fastspring.builder.checkout();
        }}
      >
        {t('action.buyNow')}
      </button>
    </>
  );
}
