import styled from 'styled-components';

export const PlanList = styled.div.attrs({
  className: 'row',
})`
  display: flex;
  justify-content: space-between;
`;

export const CardContainer = styled.div.attrs({
  className: 'col-sm-6 col-xl-3 d-flex',
})``;
