import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

export default function ArrowLink({ noLink, className, ...props }) {
  if (noLink) {
    return (
      <button className={`btn btn-link btn-inline ${className}`} {...props}>
        {props.children}&nbsp;&rsaquo;
      </button>
    );
  }
  return (
    <ArrowLink.Link {...props} className={className}>
      {props.children}&nbsp;&rsaquo;
    </ArrowLink.Link>
  );
}

ArrowLink.Link = styled(Link)`
  display: inline-block;
`;
