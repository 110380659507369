import { stateList } from '@data/states';
import i18n from '../i18n/i18n';

export function countryList() {
  return {
    US: i18n.t('countries:US'),
    AF: i18n.t('countries:AF'),
    AX: i18n.t('countries:AX'),
    AL: i18n.t('countries:AL'),
    DZ: i18n.t('countries:DZ'),
    AS: i18n.t('countries:AS'),
    AD: i18n.t('countries:AD'),
    AO: i18n.t('countries:AO'),
    AI: i18n.t('countries:AI'),
    AQ: i18n.t('countries:AQ'),
    AG: i18n.t('countries:AG'),
    AR: i18n.t('countries:AR'),
    AM: i18n.t('countries:AM'),
    AW: i18n.t('countries:AW'),
    AU: i18n.t('countries:AU'),
    AT: i18n.t('countries:AT'),
    AZ: i18n.t('countries:AZ'),
    BS: i18n.t('countries:BS'),
    BH: i18n.t('countries:BH'),
    BD: i18n.t('countries:BD'),
    BB: i18n.t('countries:BB'),
    BY: i18n.t('countries:BY'),
    BE: i18n.t('countries:BE'),
    BZ: i18n.t('countries:BZ'),
    BJ: i18n.t('countries:BJ'),
    BM: i18n.t('countries:BM'),
    BT: i18n.t('countries:BT'),
    BO: i18n.t('countries:BO'),
    BA: i18n.t('countries:BA'),
    BW: i18n.t('countries:BW'),
    BV: i18n.t('countries:BV'),
    BR: i18n.t('countries:BR'),
    IO: i18n.t('countries:IO'),
    BN: i18n.t('countries:BN'),
    BG: i18n.t('countries:BG'),
    BF: i18n.t('countries:BF'),
    BI: i18n.t('countries:BI'),
    KH: i18n.t('countries:KH'),
    CM: i18n.t('countries:CM'),
    CA: i18n.t('countries:CA'),
    CV: i18n.t('countries:CV'),
    KY: i18n.t('countries:KY'),
    CF: i18n.t('countries:CF'),
    TD: i18n.t('countries:TD'),
    CL: i18n.t('countries:CL'),
    CN: i18n.t('countries:CN'),
    CX: i18n.t('countries:CX'),
    CC: i18n.t('countries:CC'),
    CO: i18n.t('countries:CO'),
    KM: i18n.t('countries:KM'),
    CG: i18n.t('countries:CG'),
    CD: i18n.t('countries:CD'),
    CK: i18n.t('countries:CK'),
    CR: i18n.t('countries:CR'),
    CI: i18n.t('countries:CI'),
    HR: i18n.t('countries:HR'),
    CU: i18n.t('countries:CU'),
    CY: i18n.t('countries:CY'),
    CZ: i18n.t('countries:CZ'),
    DK: i18n.t('countries:DK'),
    DJ: i18n.t('countries:DJ'),
    DM: i18n.t('countries:DM'),
    DO: i18n.t('countries:DO'),
    EC: i18n.t('countries:EC'),
    EG: i18n.t('countries:EG'),
    SV: i18n.t('countries:SV'),
    GQ: i18n.t('countries:GQ'),
    ER: i18n.t('countries:ER'),
    EE: i18n.t('countries:EE'),
    ET: i18n.t('countries:ET'),
    FK: i18n.t('countries:FK'),
    FO: i18n.t('countries:FO'),
    FJ: i18n.t('countries:FJ'),
    FI: i18n.t('countries:FI'),
    FR: i18n.t('countries:FR'),
    GF: i18n.t('countries:GF'),
    PF: i18n.t('countries:PF'),
    TF: i18n.t('countries:TF'),
    GA: i18n.t('countries:GA'),
    GM: i18n.t('countries:GM'),
    GE: i18n.t('countries:GE'),
    DE: i18n.t('countries:DE'),
    GH: i18n.t('countries:GH'),
    GI: i18n.t('countries:GI'),
    GR: i18n.t('countries:GR'),
    GL: i18n.t('countries:GL'),
    GD: i18n.t('countries:GD'),
    GP: i18n.t('countries:GP'),
    GU: i18n.t('countries:GU'),
    GT: i18n.t('countries:GT'),
    GG: i18n.t('countries:GG'),
    GN: i18n.t('countries:GN'),
    GW: i18n.t('countries:GW'),
    GY: i18n.t('countries:GY'),
    HT: i18n.t('countries:HT'),
    HM: i18n.t('countries:HM'),
    VA: i18n.t('countries:VA'),
    HN: i18n.t('countries:HN'),
    HK: i18n.t('countries:HK'),
    HU: i18n.t('countries:HU'),
    IS: i18n.t('countries:IS'),
    IN: i18n.t('countries:IN'),
    ID: i18n.t('countries:ID'),
    IR: i18n.t('countries:IR'),
    IQ: i18n.t('countries:IQ'),
    IE: i18n.t('countries:IE'),
    IM: i18n.t('countries:IM'),
    IL: i18n.t('countries:IL'),
    IT: i18n.t('countries:IT'),
    JM: i18n.t('countries:JM'),
    JP: i18n.t('countries:JP'),
    JE: i18n.t('countries:JE'),
    JO: i18n.t('countries:JO'),
    KZ: i18n.t('countries:KZ'),
    KE: i18n.t('countries:KE'),
    KI: i18n.t('countries:KI'),
    KR: i18n.t('countries:KR'),
    KW: i18n.t('countries:KW'),
    KG: i18n.t('countries:KG'),
    LA: i18n.t('countries:LA'),
    LV: i18n.t('countries:LV'),
    LB: i18n.t('countries:LB'),
    LS: i18n.t('countries:LS'),
    LR: i18n.t('countries:LR'),
    LY: i18n.t('countries:LY'),
    LI: i18n.t('countries:LI'),
    LT: i18n.t('countries:LT'),
    LU: i18n.t('countries:LU'),
    MO: i18n.t('countries:MO'),
    MK: i18n.t('countries:MK'),
    MG: i18n.t('countries:MG'),
    MW: i18n.t('countries:MW'),
    MY: i18n.t('countries:MY'),
    MV: i18n.t('countries:MV'),
    ML: i18n.t('countries:ML'),
    MT: i18n.t('countries:MT'),
    MH: i18n.t('countries:MH'),
    MQ: i18n.t('countries:MQ'),
    MR: i18n.t('countries:MR'),
    MU: i18n.t('countries:MU'),
    YT: i18n.t('countries:YT'),
    MX: i18n.t('countries:MX'),
    FM: i18n.t('countries:FM'),
    MD: i18n.t('countries:MD'),
    MC: i18n.t('countries:MC'),
    MN: i18n.t('countries:MN'),
    ME: i18n.t('countries:ME'),
    MS: i18n.t('countries:MS'),
    MA: i18n.t('countries:MA'),
    MZ: i18n.t('countries:MZ'),
    MM: i18n.t('countries:MM'),
    NA: i18n.t('countries:NA'),
    NR: i18n.t('countries:NR'),
    NP: i18n.t('countries:NP'),
    NL: i18n.t('countries:NL'),
    AN: i18n.t('countries:AN'),
    NC: i18n.t('countries:NC'),
    NZ: i18n.t('countries:NZ'),
    NI: i18n.t('countries:NI'),
    NE: i18n.t('countries:NE'),
    NG: i18n.t('countries:NG'),
    NU: i18n.t('countries:NU'),
    NF: i18n.t('countries:NF'),
    MP: i18n.t('countries:MP'),
    NO: i18n.t('countries:NO'),
    OM: i18n.t('countries:OM'),
    PK: i18n.t('countries:PK'),
    PW: i18n.t('countries:PW'),
    PS: i18n.t('countries:PS'),
    PA: i18n.t('countries:PA'),
    PG: i18n.t('countries:PG'),
    PY: i18n.t('countries:PY'),
    PE: i18n.t('countries:PE'),
    PH: i18n.t('countries:PH'),
    PN: i18n.t('countries:PN'),
    PL: i18n.t('countries:PL'),
    PT: i18n.t('countries:PT'),
    PR: i18n.t('countries:PR'),
    QA: i18n.t('countries:QA'),
    RE: i18n.t('countries:RE'),
    RO: i18n.t('countries:RO'),
    RU: i18n.t('countries:RU'),
    RW: i18n.t('countries:RW'),
    BL: i18n.t('countries:BL'),
    SH: i18n.t('countries:SH'),
    KN: i18n.t('countries:KN'),
    LC: i18n.t('countries:LC'),
    MF: i18n.t('countries:MF'),
    PM: i18n.t('countries:PM'),
    VC: i18n.t('countries:VC'),
    WS: i18n.t('countries:WS'),
    SM: i18n.t('countries:SM'),
    ST: i18n.t('countries:ST'),
    SA: i18n.t('countries:SA'),
    SN: i18n.t('countries:SN'),
    RS: i18n.t('countries:RS'),
    SC: i18n.t('countries:SC'),
    SL: i18n.t('countries:SL'),
    SG: i18n.t('countries:SG'),
    SK: i18n.t('countries:SK'),
    SI: i18n.t('countries:SI'),
    SB: i18n.t('countries:SB'),
    SO: i18n.t('countries:SO'),
    ZA: i18n.t('countries:ZA'),
    GS: i18n.t('countries:GS'),
    ES: i18n.t('countries:ES'),
    LK: i18n.t('countries:LK'),
    SD: i18n.t('countries:SD'),
    SR: i18n.t('countries:SR'),
    SJ: i18n.t('countries:SJ'),
    SZ: i18n.t('countries:SZ'),
    SE: i18n.t('countries:SE'),
    CH: i18n.t('countries:CH'),
    SY: i18n.t('countries:SY'),
    TW: i18n.t('countries:TW'),
    TJ: i18n.t('countries:TJ'),
    TZ: i18n.t('countries:TZ'),
    TH: i18n.t('countries:TH'),
    TL: i18n.t('countries:TL'),
    TG: i18n.t('countries:TG'),
    TK: i18n.t('countries:TK'),
    TO: i18n.t('countries:TO'),
    TT: i18n.t('countries:TT'),
    TN: i18n.t('countries:TN'),
    TR: i18n.t('countries:TR'),
    TM: i18n.t('countries:TM'),
    TC: i18n.t('countries:TC'),
    TV: i18n.t('countries:TV'),
    UG: i18n.t('countries:UG'),
    UA: i18n.t('countries:UA'),
    AE: i18n.t('countries:AE'),
    GB: i18n.t('countries:GB'),
    UM: i18n.t('countries:UM'),
    UY: i18n.t('countries:UY'),
    UZ: i18n.t('countries:UZ'),
    VU: i18n.t('countries:VU'),
    VE: i18n.t('countries:VE'),
    VN: i18n.t('countries:VN'),
    VG: i18n.t('countries:VG'),
    VI: i18n.t('countries:VI'),
    WF: i18n.t('countries:WF'),
    EH: i18n.t('countries:EH'),
    YE: i18n.t('countries:YE'),
    ZM: i18n.t('countries:ZM'),
    ZW: i18n.t('countries:ZW'),
  };
}

export function getCountryNameFromCountryCode(countryCode) {
  if (countryList().hasOwnProperty(countryCode)) {
    return countryList()[countryCode];
  } else {
    return countryCode;
  }
}

export function getCountryCodeFromCountryName(countryName) {
  return Object.keys(countryList()).find(code => {
    return countryList()[code] === countryName;
  });
}

export function getStateCodeFromStateName(stateName) {
  const list = { ...stateList.US.values, ...stateList.CA.values };
  return Object.keys(list).find(code => {
    return list[code] === stateName;
  });
}
